<template>
  <section>
    <div class="w-full my-2 bg-white p-4">
      <div class="w-full flex justify-between items-center">
        <div>
          <p class="text-lg font-bold text-blue-800">Listado de áreas</p>
        </div>
        <Button icon="pi pi-th-large" label="Crear área"
                @click="$router.push({ name: 'pharmasan.recursos-humanos.configuracion.areas.crear-area' })"/>
      </div>
      <div class="w-full mt-4">
        <DataTable :rowsPerPageOptions="[10,20,50]" :paginator="true" :rows="10" :value="areas"
                   responsiveLayout="scroll" dataKey="id"
                   v-model:filters="filters" filterDisplay="menu"
                   :loading="loading"
                   :rowHover="true"
                   columnResizeMode="fit"
                   :globalFilterFields="['name']"
                   >
          <template #loading>
            Cargando información. Por favor espere.
          </template>
          <template #empty>
            no hay informacion para mostrar.
          </template>
          <Column headerClass="text-xs" bodyClass="text-xs" header="Nombre" field="name"
                  bodyStyle="text-align: center;justify-content: center;">
            <template #filter="{filterModel}">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter" :placeholder="`Buscar por nombre...`"/>
            </template>
            <template #body="slotProps">
              {{ slotProps.data.name_parent ? slotProps.data.name_parent : slotProps.data.name }}
            </template>
          </Column>
          <Column headerClass="text-xs" bodyClass="text-xs" header="Tipo de area"
                  bodyStyle="text-align: center;justify-content: center;">
            <template #body="slotProps">
              <div class="text-center px-4" v-if="slotProps.data.name_parent">
                <p class="p-2 rounded-sm bg-blue-300 text-blue-800 font-medium">
                  Area secundaria
                </p>
              </div>
              <div class="text-center px-4" v-else>
                <p class="p-2 rounded-sm bg-green-300 text-green-800 font-medium">
                  Area principal
                </p>
              </div>
            </template>
          </Column>
          <Column headerClass="text-xs" bodyClass="text-xs" field="full_name" header="Encargado de área"
                  bodyStyle="text-align: center;justify-content: center;">
            <template #filter="{filterModel}">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter" :placeholder="`Buscar por encargado...`"/>
            </template>
            <template #body="slotProps">
              {{ slotProps.data.full_name }}
            </template>
          </Column>
          <Column field="" header="Acciones" key="" bodyStyle="text-align: center;justify-content: center;">
            <template #body="{data}">
              <div>
                <Button label=""
                        icon="pi pi-pencil"
                        v-tooltip.top="'Editar Area'"
                        class="p-button-raised p-button-warning p-button-rounded"
                        @click="onEdit(data)"
                />
              </div>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </section>
</template>
<script>
import AreasService from '../../../../../services_back_new/areas.service'
import { ref, onMounted } from 'vue'
import { FilterMatchMode, FilterOperator } from 'primevue/api'
import { useRouter } from 'vue-router'

export default {
  name: 'ListadoAreas',
  setup () {
    const router = useRouter()
    const _AreasService = ref(new AreasService())
    const areas = ref([])
    const loading = ref(true)
    const filters = ref({
      name: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
      },
      full_name: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
      }
    })
    const obtenerAreas = () => {
      _AreasService.value.get().then(({ data }) => {
        for (const i of data) {
          if (i.parent_area_id) {
            areas.value.push({
              ...i,
              name_parent: data.find(a => a.id === i.parent_area_id).name + ' - ' + i.name
            })
          } else {
            areas.value.push(i)
          }
        }
      })
    }
    const onEdit = (data) => {
      router.push({ name: 'pharmasan.recursos-humanos.configuracion.areas.crear-area', params: { id: data.id } })
    }
    onMounted(() => {
      loading.value = false
      obtenerAreas()
    })
    return {
      areas,
      onEdit,
      filters,
      loading
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep(.p-column-header-content) {
  justify-content: center;
}

</style>
